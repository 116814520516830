import React, { useState } from 'react';
import Layout from '../../components/layout';
import ServiceFooter from '../../components/ServiceFooter';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { graphql } from 'gatsby';
import { imagePathURL } from "../../utils/JSONData";
import dotImg from '../../Assets/images/Home/dotImg.png';
import Pulse from 'react-reveal/Pulse';
import Fade from 'react-reveal/Fade';
import "../../Assets/styles/services.scss";
import '../../Assets/images/Services/Database.png'
import { stacks } from '../../Assets/images/stack';
import Scrolltop from '../../components/Scrolltotop/scrolltop';
import Footer from '../../components/Footer/Footer';

const Reactjs = ({ data }) => {

  const reactServices = [
    {
      id: 0,
      title: 'React Consultation',
      details: 'Our React consultants start from the ground up to meet your React app development requirements with excellence.',
      image: "https://admin.madhavtechno.com/uploads/Consultation_a6fcb3e606.png"
    },
    {
      id: 1,
      title: 'React Native Migration',
      details: 'Elevate your existing applications by seamlessly transitioning to React for efficient mobile app development.',
      image: "https://admin.madhavtechno.com/uploads/Development_781bf6f65c.png"
    },
    {
      id: 2,
      title: 'React Resource Augmentation',
      details: 'Harness the expertise of dedicated React developers to build agile and secure mobile apps.',
      image: "https://admin.madhavtechno.com/uploads/Development_1b899a311e.png"
    },
    {
      id: 3,
      title: 'React Development',
      details: 'Our adept React developers craft high-performing native web applications that are cross-platform compatible.',
      image: "https://admin.madhavtechno.com/uploads/UI_Design_410d03b060.png"
    },
    {
      id: 4,
      title: 'React UI Design',
      details: 'Create captivating UI designs with feature-rich functionalities using our React development expertise.',
      image: "https://admin.madhavtechno.com/uploads/Resource_9f81998013.png"
    },
    {
      id: 5,
      title: 'React Maintenance and Support',
      details: 'As a leading React app development company, we provide consistent post-deployment error handling and maintenance services.',
      image: "https://admin.madhavtechno.com/uploads/Maintenance_48c82ee210.png"
    }
  ]

  const reacttechnologyStack = [
    {
      id: 2,
      title: 'Front-end',
      image: 'https://admin.madhavtechno.com/uploads/Frontend_08a71e145a.png',
      dataArr: [
        { name: 'Material-UI', image: stacks.materialui  },
        { name: 'Chakra UI', image: stacks.chakraui },
        { name: 'Tailwind CSS', image: stacks.tailwindcss },
        { name: 'Ant design', image: stacks.Antdesign }
      ]
    },
    {
      id: 4,
      title: 'Server Side rendering',
      image: 'https://admin.madhavtechno.com/uploads/Database_de87ff3bcc.png',
      dataArr: [
        { name: 'NextJS', image: stacks.nextjs },
        { name: 'Gastby', image: stacks.gatsbyjs },
        { name: 'Remix', image: stacks.remix },
        { name: 'Razzle', image: stacks.razzle }
      ]
    },
    {
      id: 5,
      title: 'Cloud & DevOps',
      image: 'https://admin.madhavtechno.com/uploads/Clouddevops_d86784a818.png',
      dataArr: [
        { name: 'AWS', image: stacks.AWS },
        { name: 'Google Cloud', image: stacks.GoogleCloud },
        { name: 'Azure', image: stacks.Azure },
        { name: 'Docker', image: stacks.Docker },
        { name: 'Kubernetes', image: stacks.Kubernetes },
        { name: 'CI/CD', image: stacks.CICD },
        { name: 'Jenkins', image: stacks.Jenkins },
        { name: 'Heroku', image: stacks.Heroku }
      ]
    },
    {
      id: 1,
      title: 'Others',
      image: 'https://admin.madhavtechno.com/uploads/Others_2f8e35a9b8.png',
      // dataArr: ['Trello', 'RESTful API', 'BLE', 'Jira', 'Socket.IO', 'Slack', 'Redux', 'GraphQL', 'Third Party API/SDK Integration']
      dataArr: [
        { name: 'Trello', image: stacks.Trello },
        { name: 'Jira', image: stacks.Jira },
        { name: 'Socket.IO', image: stacks.SocketIO },
        { name: 'RESTful API', image: stacks.restapi },
        { name: 'GraphQL', image: stacks.GraphQL },
        { name: 'Slack', image: stacks.Slack }
      ]
    },
    // {
    //   id: 0,
    //   title: 'Backend',
    //   image: 'https://admin.madhavtechno.com/uploads/Backend_a781633728.png',
    //   dataArr: ['.Net', 'PHP', 'Java', 'Node', 'Ruby on Rails']
    // },
    // {
    //   id: 3,
    //   title: 'Testing',
    //   image: 'https://admin.madhavtechno.com/uploads/Testing_7725967b2e.png',
    //   dataArr: ['Appium', 'Katalon Test Studio', 'BrowserStack']
    // },


  ]


  // const { allServices, allServiceDevelopmentServices, allTechnologyStacks, allGetInTouchs } = data

  const [selectedStack, setSelectedStack] = useState(0);

  const handleStackClick = (index) => {
    setSelectedStack(index);
  };

  

  return (
    <>
      <Layout>
        <div className="service_react_group">
          <Row>
            <Col sm={12} md={8} className="m-auto">
              <div>
                <div className="service_react_title">
                  React web App Development Company India
                </div>
                <div className="service_react_subtitle">
                  Craft innovative web applications with React's prowess in delivering responsiveness and speed.
                </div>
                <div className="service_react_subtitle2">
                  Extend your reach across platforms with our cross-platform web and mobile applications powered by React. Our expertise in React allows us to create versatile solutions that cater to both
                  web and mobile users, ensuring cost-effectiveness and wider market coverage. Embrace the advantages of React for consistent, high-quality user experiences.
                </div>
                <div className="service_react_button">
                  <button className="service_freequote">GET A FREE QUOTE</button>
                  <button className="service_ourwork">OUR WORK</button>
                </div>
              </div>
            </Col>
            <Col sm={12} md={4}>
              <div>
                <Pulse>
                  {/* <img src={imagePathURL + allServices.nodes[0].data.attributes.image.data.attributes.url} alt="react-logo" /> */}
                  <img src="https://www.svgrepo.com/show/452092/react.svg" alt="react-logo" />
                </Pulse>
              </div>
            </Col>
          </Row>
        </div>
        <div className="position-relative">
          <div className="service_react_title px-4 text-center">
            {/* {allServices.nodes[0].data.attributes.section1Title} */}
            React Development Services
          </div>
          <div className="service_react_subtitle px-4 text-center">
            {/* {allServices.nodes[0].data.attributes.section1Desc} */}
            Fuel business growth with bespoke and quality-driven React App Development Services
          </div>
          <div className="service_cross-platform">Cross Platform</div>
          <img src={dotImg} alt="dot" className="service_dot-image" />
          {/* <div className="service_RN_develop">
            {allServiceDevelopmentServices.nodes[0].data.map((item, i) => {
              return (
                <Fade top>
                  <div className="service_develop_group" key={i}>
                    <img className="service_developimg" src={imagePathURL + item?.attributes.image.data.attributes.url} alt="searvice" />
                    <div className="service_developtitle">{item.attributes.title}</div>
                    <div className="service_developdetails">{item.attributes?.description}</div>
                  </div>
                </Fade>
              );
            })}
          </div> */}
          <div className="service_RN_develop">
            {reactServices.map((item, i) => {
              return (
                <Fade top key={i}>
                  <div className="service_develop_group">
                    {/* <img className="service_developimg" src={item.image} alt="service" /> */}
                    <div className="service_developtitle">{item.title}</div>
                    <div className="service_developdetails">{item.details}</div>
                  </div>
                </Fade>
              );
            })}
          </div>
        </div>
        <div className="service_quote_group">
          <div className="service_title_group">
            <div className="service_quote_title">
              {/* {allGetInTouchs.nodes[0].data.attributes.title} */}
              Would you like to get in touch with us?
            </div>
            <div className="service_quote_details">
              {/* {allGetInTouchs.nodes[0].data.attributes.description} */}
              Please send us your React Native App Development requirement by simply clicking the Get a Free Quote Button.
            </div>
          </div>
          <div>
            <button className="service_quote_button">
              {/* {allGetInTouchs.nodes[0].data.attributes.btnTxt} */}
              GET A FREE QUOTE
            </button>
          </div>
        </div>
        <div className="service_trofi_content">
          <div className="service_react_title text-center mx-2">
            {/* {allServices.nodes[0].data.attributes.section2Title} */}
            Customized React Applications
          </div>
          <div
            className="service_react_subtitle text-center mx-2"
          >
            {/* {allServices.nodes[0].data.attributes.section2Subtitle} */}
            Our React developers team can accelerate your development process and enhance user experience
          </div>
          <div className="service_trofi_subcontent">
            <Row>
              <Col sm={12} md={6} className="m-auto">
                <div className="service_trofi_title_content">
                  <div className="service_trofi_title">
                    {/* {allServices.nodes[0].data.attributes.section2ContentTitle} */}
                    Trofi: Earn Interest on Crypto App
                  </div>
                  <div className="service_developdetails">
                    {/* {allServices.nodes[0].data.attributes.section2ContentSubTitle} */}
                    Mobile Application Development
                  </div>
                  <div className="service_trofi_details">
                    {/* {allServices.nodes[0].data.attributes.section2ContentDesc} */}
                    Earn interest with all-in-one wealth management app for your crypto assets. Swap or buy crypto, and make passive income on crypto investment.Are you looking for a multi-purpose crypto wealth management app to help you gain better control over your crypto assets? Would you like to make crypto investment and make passive income from interest on your investments? If that is the case, this licensed and regulated crypto platform is the perfect place for you to get started. Buy crypto or swap crypto assets, get crypto loan and earn crypto currency from your investments with one of the best crypto apps available online. This crypto currency app lets you deposit your currency for short term or long term, so you can get better interests and earn crypto income easily.
                  </div>
                  <Fade top>
                    {/* <button className="service_viewCaseStudy"> {allServices.nodes[0].data.attributes.section2ContentBtnTxt}</button> */}
                    <button className="service_viewCaseStudy">VIEW CASE STUDY</button>
                  </Fade>
                </div>
              </Col>
              <Col sm={12} md={6} className="m-auto text-center">
                <div>
                  <Pulse>
                    <img
                      className="service_trofiDevice_img"
                      // src={imagePathURL + allServices.nodes[0].data.attributes.section2ContentImage.data.attributes.url}
                      src={imagePathURL + '/uploads/Trofi_Image_2043c85102.png'}
                      alt="trofi"
                    />
                  </Pulse>
                </div>
              </Col>
            </Row>
          </div>
          <div className="service_react_title text-center m-auto">
            <span style={{ display: "inline-block" }}>

              Technology Stack
              <div className='text-item' style={{ display: "flex", justifyContent: "center",padding:'5px 0 29px 0' }} >
                <div style={{ borderBottom: "3px solid #FF9C4F", borderRadius: "10px", marginTop: "10px", width: "25%", marginRight: "10px" }}></div>
                <div style={{ borderBottom: "3px solid #FF9C4F", borderRadius: "10px", marginTop: "10px", width: "3%" }}></div>
                <div style={{ borderBottom: "3px solid #FF9C4F", borderRadius: "10px", marginTop: "10px", width: "25%", marginLeft: "10px" }}> </div>
              </div>
            </span>
          </div>
          {/* <div>
            <div className="service_line1"></div>
          </div> */}
          {/* <div className="service_stack_content">
            {allTechnologyStacks.nodes[0].data.map((item, i) => {
              return (
                <Fade top>
                  <div className="service_stack_group" key={i}>
                    <div className="service_stack_title_content">
                      <img src={imagePathURL + item.attributes?.image.data.attributes.url} alt="technology" />
                      <div className="service_stack_title">{item.attributes.title}</div>
                    </div>
                    <div className="service_arr_group">
                      {item.attributes.technologyName?.replace(/\[|\]/g, '').split(',').map((d, i) => {
                        return (
                          <div
                            className="service_quote_details"
                            style={{
                              margin: "auto",
                              display: "flex",
                              alignItems: "center",
                              fontSize: '14px'
                            }}
                            key={i}
                          >
                            <div className="service_bullets"></div>
                            {d}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Fade>
              );
            })}
          </div> */}
          {/* <div className="service_stack_content">
            {reacttechnologyStack.map((item, i) => {
              return (
                <Fade top>
                  <div className="service_stack_group" key={i}>
                    <div className="service_stack_title_content">
                      <img src={item.image} alt="technology" />
                      <div className="service_stack_title">{item.title}</div>
                    </div>
                    <div className="service_arr_group">
                      {item.dataArr.map((technology, i) => (
                        <div className="service_quote_details" 
                        style={{
                          margin: "auto",
                          display: "flex",
                          alignItems: "center",
                          fontSize: '14px'
                        }} key={i} >
                          <div className="service_bullets"></div>
                          {technology}
                        </div>
                      ))}
                    </div>
                  </div>
                </Fade>
              );
            })}
          </div> */}

          {/* <Tabs defaultActiveKey="second">
            <Tab eventKey="first" title="Backend">
              <div class="tab-pane fade active show" id="NextGen" role="tabpanel" aria-labelledby="NextGen-tab"><div class="row justify-content-center"><div class="col-4 col-sm-4 col-md-3 col-lg-2 text-center">
                <a href="/ai-ml-dl-development.html">
                  <picture class="tech-icons">
                    <img width="84" height="84" src="assets/img/home/technologystacks/ai.svg" data-src="assets/img/home/technologystacks/ai.svg" alt="AI ML Solutions" class="no-src img-fluid lazyloaded" data-pagespeed-url-hash="31059146" onload="pagespeed.CriticalImages.checkImageForCriticality(this);" />
                  </picture>
                  <h5>AI ML Solutions</h5>
                </a>
              </div>
              </div>
              </div>
            </Tab>
            <Tab eventKey="second" title="Others">
              Hii, I am 2nd tab content
            </Tab>
            <Tab eventKey="third" title="Front-end">
              Hii, I am 3rd tab content
            </Tab>
            <Tab eventKey="four" title="Testing">
              Hii, I am 3rd tab content
            </Tab>
            <Tab eventKey="five" title="Database">
              Hii, I am 3rd tab content
            </Tab>
            <Tab eventKey="six" title="Cloud & DevOps">
              Hii, I am 3rd tab content
            </Tab>
          </Tabs> */}
          {/* <Tabs defaultActiveKey={activeTab} onSelect={(key) => setActiveTab(key)}>
            {reacttechnologyStack.map((stack) => (
              <Tab key={stack.id} eventKey={stack.title.toLowerCase()} title={stack.title}>
                <div className="tab-content">
                  <div className="stack-images">
                    {stack.dataArr.map((tech, index) => (
                      <div key={index} className="stack-image">
                        <img src={tech} alt={stack.title} style={{ maxWidth: '100px', maxHeight: '100px' }} />
                        <div className="stack-name">{tech}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </Tab>
            ))}
          </Tabs> */}

          <div className='technology'>
            <div className='technologytab'>
              {reacttechnologyStack.map((stack, index) => (
                <ul className={`tabbar-ul ${selectedStack === index ? 'active' : ''}`} key={index}>
                  <li
                    className='tabbar-li'
                    onClick={() => handleStackClick(index)}
                  >
                    {stack.title}
                  </li>
                </ul>
              ))}
            </div>
            <hr />
            <div className='technologydetalis'>
              {/* {selectedStack && ( */}
                <div className='stackdata'>
                  {reacttechnologyStack[selectedStack].dataArr.map((item, index) => (
                    <ul>
                    {/* <img src="https://admin.madhavtechno.com/uploads/Maintenance_48c82ee210.png" /> */}
                    <img src={item.image} alt={selectedStack.title} style={{ height: item.name === 'Remix' ? 70 : '' }} />
                    <li key={index}>{item.name}</li>
                  </ul>
                  ))}
                </div>
              {/* )} */}
            </div>
          </div>

        </div>
        {/* <ServiceFooter /> */}
        <Footer />
        <Scrolltop />
      </Layout>
    </>
  )
}

// export const query = graphql`
//   {
//     allServices {
//       nodes {
//         data {
//           attributes {
//             description1
//             description2
//             image {
//               data {
//                 attributes {
//                   url
//                 }
//               }
//             }
//             section1Desc
//             section1Title
//             section2ContentBtnTxt
//             section2ContentDesc
//             section2ContentImage {
//               data {
//                 attributes {
//                   url
//                 }
//               }
//             }
//             section2ContentSubTitle
//             section2ContentTitle
//             section2Subtitle
//             section2Title
//             title
//           }
//         }
//       }
//     }

//     allServiceDevelopmentServices {
//       nodes {
//         data {
//           attributes {
//             description
//             title
//             image {
//               data {
//                 attributes {
//                   url
//                 }
//               }
//             }
//           }
//         }
//       }
//     }

//     allTechnologyStacks {
//       nodes {
//         data {
//           attributes {
//             technologyName
//             title
//             image {
//               data {
//                 attributes {
//                   url
//                 }
//               }
//             }
//           }
//         }
//       }
//     }

//     allGetInTouchs {
//       nodes {
//         data {
//           attributes {
//             title
//             description
//             btnTxt
//           }
//         }
//       }
//     }
//   }
// `;

export default Reactjs